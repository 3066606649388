import { useContext, useMemo } from "react";
import DruidWebchatContext, { DruidWebchatContextType } from "../WebChatContext";
import { DirectLine, DirectLineOptions, ConnectionStatus } from 'botframework-directlinejs';

const createFormDataFunc = attachments => {
  const formData = new FormData();

  attachments.forEach(
    ({
      contentType,
      data,
      filename,
      name
    }: {
      contentType?: string;
      data: BlobPart[];
      filename?: string;
      name?: string;
    }) => {
      formData.append(name as string, new Blob(data, { type: contentType }), filename);
    }
  );

  return formData;
}
export const createDirectLineOverwrite = (options: DirectLineOptions) => {
  return new DirectLine({
    ...options,
    ...{
      createFormData: createFormDataFunc
    }
  });
}

export const useCreateDirectLine = () => {
  const [druidWebChatState, , getState] = useContext<DruidWebchatContextType>(DruidWebchatContext);
  const connection = druidWebChatState.directlineInstance || druidWebChatState.mergedChatOptions.botConnection; 
  const connectionStatus = connection?.connectionStatus$.getValue();
  return useMemo(() => {
    ensureDirectLineProps(connection, druidWebChatState.directlineOptions);
    const directLine = (connectionStatus !== ConnectionStatus.Ended && connection) || (druidWebChatState.directlineOptions && createDirectLineOverwrite(druidWebChatState.directlineOptions as any));
    return directLine;
  }, [druidWebChatState.directlineOptions, connection, connectionStatus]);
}

export const ensureDirectLineProps = (connection: DirectLine | null, dlOptions: DirectLineOptions | null) => {
  if(connection) {
    connection.initiatorUserId ??= dlOptions?.initiatorUserId || "";
    connection.initiatorBotId ??= dlOptions?.initiatorUserId || "";
  }
}
