export function loadConfiguration() {
    
}

export  function doAuthorize({baseUrl, authorizationUrl, authorizationDto, withCredentials, customAuthHeaders}: {baseUrl: Nullable<string>, authorizationUrl: Nullable<string>, authorizationDto: any, withCredentials: RequestCredentials, customAuthHeaders?: any}): Promise<Response> {
  // var ctx = useContext()
  var url_ = baseUrl + (authorizationUrl || "/api/services/app/Chat/AuthorizeAnonymousAsync");
  url_ = url_.replace(/[?&]$/, "");
  if (url_.startsWith('//')) {
      url_ = url_.replace('//', '/'); // replace '//' from start of string
  }

  return fetch(url_, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      ...customAuthHeaders
    },
    mode: 'cors',
    credentials: withCredentials,
    body: JSON.stringify(authorizationDto)
  });
}

export function doHeartBeat({baseUrl}: { baseUrl: string }) {
    baseUrl = baseUrl.replace(/[?&]$/, "");

    if (baseUrl.startsWith('//')) {
      baseUrl = baseUrl.replace('//', '/'); 
    }
    return fetch(baseUrl, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      credentials: 'same-origin',
    });
}


export function getSpeechToken({baseUrl, withCredentials, botId, oldToken}: {baseUrl: string,  withCredentials: RequestCredentials, botId: string, oldToken: string}) {
  let url_ = baseUrl + "/GetSpeechToken" + `?botId=${botId}&oldToken=${oldToken}`;
 
  url_ = url_.replace(/[?&]$/, "");
  if (url_.startsWith('//')) {
      url_ = url_.replace('//', '/'); // replace '//' from start of string
  }

  return fetch(url_, {
      method: 'GET',
      mode: 'cors',
      credentials: withCredentials,
      headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
      }
  });
}

export function encryptSensitiveData({baseUrl, withCredentials, dataToEncrypt, conversationToken }: {baseUrl: string,  withCredentials: RequestCredentials, dataToEncrypt: string, conversationToken: string }) {
  let url_ = `${baseUrl}/api/services/app/Bot/Encrypt`
 
  url_ = url_.replace(/[?&]$/, "");
  if (url_.startsWith('//')) {
      url_ = url_.replace('//', '/'); // replace '//' from start of string
  }

  return fetch(url_, {
      method: 'POST',
      mode: 'cors',
      credentials: withCredentials,
      headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": `Bearer ${conversationToken}`
      },
      body: JSON.stringify(dataToEncrypt)
  });
}

export function searchAssist({apcUrl, withCredentials, searchText, abpToken, botId, languageCode }: {apcUrl: string,  withCredentials: RequestCredentials, searchText: string, abpToken: string, botId: string, languageCode: string }) {
  if(!apcUrl) {
    return Promise.resolve("");
  }
  let url_ = `${apcUrl}/api/services/app/Bot/TestMultiSentenceMachineLearning`
 
  url_ = url_.replace(/[?&]$/, "");
  if (url_.startsWith('//')) {
      url_ = url_.replace('//', '/'); // replace '//' from start of string
  }

  const input = {
    modelId: botId,
    intent: searchText,
    languageCode
  }
  return fetch(url_, {
      method: 'POST',
      mode: 'cors',
      credentials: withCredentials,
      headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": `Bearer ${abpToken}`
      },
      body: JSON.stringify(input)
  });
}


  