import { toByteArray } from 'base64-js';

export function parseQueryString(url) {
    if(!url) url = window.location.search;
    var query = url.startsWith("?") ? url.substr(1) : url;
    var result: string[] = [];
    query.split("&").forEach(function(part) {
      result.push(part);
    });
    return result;
  }

export function ensureHttpProtocol(urlString) {
  var pat = /^https?:\/\//i;
  if (!pat.test(urlString))
  {
    urlString = 'https://' + urlString;
  }

  return urlString;
}

export function druidCreateBlob(BASE64: string, type: string) {
  return URL.createObjectURL(new Blob([toByteArray(BASE64)], { type: type }));
}
export function druidCreateBlobUrl(BASE64: string, type: string) {
    return `url('${druidCreateBlob(BASE64, type)}')`;
}