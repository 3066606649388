import deepmerge from "deepmerge";
import { WebChatState } from "../WebChatContext";

export function buildContextMenuWithTranslations(contextMenuItems: WebChatState["contextMenuItems"], contextMenuTranslations: WebChatState["contextMenuTranslations"], defaultLanguage: string | undefined, selectedLanguage: string | undefined) {
    const defaultContextMenuTranslation = contextMenuTranslations[defaultLanguage || ""] || contextMenuItems || [];
    const selectedContextMenuTranslation = deepmerge(defaultContextMenuTranslation, contextMenuTranslations[selectedLanguage || ""] || [],
        {
            arrayMerge: (target, source, _) => {
                const destination = target.slice();
                source.forEach((_, index) => {
                    destination[index] = source[index] || destination[index];
                });
                return destination;
            }
        });

    return selectedContextMenuTranslation;
}