import { useContext, useEffect } from "react";
import DruidWebchatContext, { DruidWebchatContextType } from "../WebChatContext";

export const useContainerElementToggle = ({ initialOptions}) => {
    const [druidWebChatState, druidWebChatDispatch, getState] = useContext<DruidWebchatContextType>(DruidWebchatContext);

    useEffect(() => {
        const openWebchatAction = () => {
          if(getState().minimized) {
            return druidWebChatDispatch({
              type: 'OPEN_WEBCHAT'
            });
          }
        };
        if(druidWebChatState.loaded === true) {
          initialOptions.UI?.containerElement?.addEventListener('click', openWebchatAction);
        }
        return () => {
          initialOptions.UI?.containerElement?.removeEventListener('click', openWebchatAction);
        }
      }, [initialOptions.UI?.containerElement, druidWebChatDispatch, druidWebChatState.loaded, getState]);
}