
import './webpackPublicPath';
import React from 'react'
import ReactDOM from 'react-dom';
import './index.scss';
import root from 'react-shadow';
import App from './App';
import { isPlainObject } from 'is-plain-object';
import * as merge from 'deepmerge';


// import * as serviceWorker from './serviceWorker';
import { DruidCookies, overridenBFStyleOptions, webChatDefaultOptions, WebChatOptions } from './Webchat/WebChatOptions';

import { mergeChatOptions, visitMergedChatOptions } from './Webchat/utils/mergeChatOptions';
import { randomHash } from './Webchat/utils/randomHash';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'react-loading-skeleton/dist/skeleton.css'
import { newMessageNotificationSound } from './resources/audio/notificationSounds';
import { druidCreateBlob } from './Webchat/utils/urlUtils';
import { mobileAndTabletcheck } from './Webchat/utils/mobileAndTabletCheck';
import Cookies from 'js-cookie';
import { removeAllCookies, removeCookie } from './Webchat/utils/cookiesUtils';

// import * as ACData from "adaptivecards-templating";
// import * as AdaptiveCards from "adaptivecards";

// import * as babel from '@babel/standalone';

// export * as babel from '@babel/standalone';

// const url = new URL((document.currentScript as HTMLScriptElement).src);
// const widgetLink = url.href.substring(0, url.href.lastIndexOf('/') + 1);
// __webpack_public_path__ = widgetLink;

export { default as React } from 'react';
export * as ReactDOM from 'react-dom';
export  { default as Cookies } from 'js-cookie';
export { webChatDefaultOptions };
export { visitMergedChatOptions, overridenBFStyleOptions };
export  { put, takeLatest, all, take, call, race , takeEvery} from 'redux-saga/effects';
export  { default as defaultDruidLocalization }  from './Webchat/logics/defaultDruidLocalization.json';
export { makeThumbnail } from './Webchat/utils/pictureUtils';
export { default as sendFiles } from 'botframework-webchat-core/lib/actions/sendFiles';
export { createDirectLineOverwrite as createDirectLine } from './Webchat/hooks/useCreateDirectLine';
export { druidLanguageComparison, isNewActivity } from "./Webchat/logics/druidBusinessLogic";
export  { default as Chat } from './App';

export { default as platform } from 'platform';
export {default as activityFromBot } from 'botframework-webchat-core/src/definitions/activityFromBot';


export var druidWebChatInstance: any = null;
export var multichatInstance: any = null;
export var notificationsSounds: any = {

};
export var initialOptions: WebChatOptions;

export const init = (options: WebChatOptions): Promise<boolean> => {

    commonInit();
    initialOptions = options;
    options.instanceID = randomHash();

    var rootElem = document.getElementById("druidWebchatRoot_v2") ||  document.createElement("div");
    rootElem.setAttribute("id", "druidWebchatRoot_v2");
    rootElem.className = "";
    rootElem.classList.add('druidWebchatRoot_v2', options.instanceID);
    rootElem.style.height = initialOptions.UI?.parentElement ? '100%': 'auto';
    rootElem.style.width = initialOptions.UI?.parentElement ? '100%': 'auto';

    var rootElemShadow =  (initialOptions.UI?.parentElement  || document.body);//.attachShadow({mode: 'open'});
    var newElem = rootElemShadow.appendChild(rootElem);
    
    initialOptions.isWidget = true;
    return new Promise<boolean>((resolve) => { options.onLoadHandlerPromise = resolve; ReactDOM.render(<App key={options.instanceID} ref={(instance) => {druidWebChatInstance = instance;}} {...options}/>, newElem); });
}

export const renderWebchat = (options: WebChatOptions): void => {
    commonInit();

    initialOptions = options;
    initialOptions.instanceID = randomHash();
    if(initialOptions.authorizationResultDto) 
    {
       if(!initialOptions.botId) {
        initialOptions.botId = initialOptions.authorizationResultDto.botId
       }
       if(!initialOptions.baseUrl) {
        initialOptions.baseUrl = initialOptions.authorizationResultDto.botDto?.apiUrl;
       }
       if(!initialOptions.userId) {
        initialOptions.userId = initialOptions.authorizationResultDto.userId
       }
    } else {
        throw new Error('[Error]: please provide `authorizationResultDto`.');
    }

    if(initialOptions.conversationTokenEnabled === undefined || initialOptions.conversationTokenEnabled === null) {
        initialOptions.conversationTokenEnabled = false;
    }   

    
   
    var rootElem = document.getElementById("druidWebchatRoot_v2") || document.createElement("div");
    rootElem.setAttribute("id", "druidWebchatRoot_v2");
    rootElem.classList.add('druidWebchatRoot_v2', options.instanceID);
    rootElem.style.height = initialOptions.UI?.parentElement ? '100%': 'auto';
    rootElem.style.width = initialOptions.UI?.parentElement ? '100%': 'auto';

    var newElem = (initialOptions.UI?.parentElement  || document.body).appendChild(rootElem);

    initialOptions.isWidget = false;
    ReactDOM.render(<App key={options.instanceID} ref={(instance) => druidWebChatInstance = instance} {...initialOptions}/>, newElem);
}

export const commonInit = () => {
    loadReact(); // used for babel
    onResizeHandler();
    addWindowListeners();
    initNotifications();
}

export const reInitializeChat = (withNewOptions?: WebChatOptions) => {
    // if(druidWebChatInstance) {
        // druidWebChatInstance.druidWebChatDispatch({
        //     type: 'RESET',
        //     payload: withNewOptions
        // });
        const initFunction = initialOptions.isWidget ? init : renderWebchat
        // MM: reInitializeChat with init call is not equal to type: 'RESET' action. reset keeps previous mergedChatOptions form state.
        if(withNewOptions) {
            initFunction(mergeChatOptions(initialOptions, withNewOptions));
            // init(merge.all<WebChatOptions>([initialOptions, withNewOptions], { isMergeableObject: isPlainObject }));
        } else {
            initFunction(initialOptions);
        }

        
    // }
}

export const open = () => {
    if(druidWebChatInstance) {
        druidWebChatInstance.druidWebChatDispatch({
            type: 'OPEN_WEBCHAT'
        });
    }
}


export const collapse = () => {
    if(druidWebChatInstance) {
        druidWebChatInstance.druidWebChatDispatch({
            type: 'CLOSE_WEBCHAT'
        });
    }
}

export const destroy = (clearCookies: boolean) => {
    var rootElem = document.getElementById("druidWebchatRoot_v2");

    if(clearCookies) {
        removeAllCookies(druidWebChatInstance.getState());
    }

    rootElem && ReactDOM.unmountComponentAtNode(rootElem);
}
export const changeLocale = (locale) => {
    if(druidWebChatInstance) {
        // druidWebChatInstance.druidWebChatDispatch({
        //     type: 'SET_MERGEDCHATOPTIONS',
        //     payload: {
        //         locale
        //     }
        // });
        druidWebChatInstance.druidWebChatDispatch({
            type: 'SET_SELECTEDLANGUAGE',
            payload: locale
          });
    }
}

export const sendMessage = (message) => {
    if(druidWebChatInstance && message) {
        druidWebChatInstance.bfDispatch({
            type: 'WEB_CHAT/SEND_MESSAGE',
            payload: {
                text: message
            }
        });
    }
}

// TO CHECK IF event.value is object, NOT STRING!!!!!!!!!!!!!!!!!!!!!!!!!!!! will not work with string
export const sendEvent = (event) => {
    if(druidWebChatInstance && event) {
        // if(event.value && typeof event.value === "object") {
        //     event.value = JSON.stringify(event.value);
        // }
        druidWebChatInstance.bfDispatch({
            type: 'WEB_CHAT/SEND_EVENT',
            payload: event
          });
    }
}

export const loadReact = () => {
    if(!(window as any).React) {
        (window as any).React = React;
    }
    if(!(window as any).ReactDOM) {
        (window as any).ReactDOM = ReactDOM;
    }
}

export const getMergedChatOptions = () => {
    return druidWebChatInstance?.getState()?.mergedChatOptions;
}


export const disableChat = (disable?: boolean) => {
    const isDisabled = disable === true || disable === undefined;
    druidWebChatInstance?.druidWebChatDispatch({type: 'SET_MERGEDCHATOPTIONS', payload: { disabled: isDisabled }});
}
export const setActivityStatusBotName = (name) => {
    druidWebChatInstance?.druidWebChatDispatch({type: 'SET_MERGEDCHATOPTIONS', payload: { UI: { activityStatusBotName: name }}});
}

export const setActivityStatusUserName = (name) => {
    druidWebChatInstance?.druidWebChatDispatch({type: 'SET_MERGEDCHATOPTIONS', payload: { UI: { activityStatusUserName: name }}});
}

export const setMergedChatOptionsSimpleValue = (variablePath: string[], variableValue: any) => {
    druidWebChatInstance?.druidWebChatDispatch({type: 'SET_MERGEDCHATOPTIONS_SIMPLE_VARIABLE', payload: { variablePath: [...variablePath], variableValue: variableValue }})
}
export const druidDispatch = (action) => {
    return druidWebChatInstance?.druidWebChatDispatch(action);
}

export const bfDispatch = (action) => {
    return druidWebChatInstance?.bfDispatch(action);
}

export const getActivities = () => {
    return druidWebChatInstance?.getState()?.store?.getState()?.activities;
}

export const prepareConversationHistoryActivities = (activities: any[]) => {
    if(!activities?.length) {
        return [];
    }
    const [firstActivity] = activities;
    const [lastActivity] = activities.slice(-1);
    const loadAllMessagesActivity = { from: { role:"bot_history"}, channelData: { conversationHistory:  { loadAllMessages: true}}, type: "message", "originator": 1, conversationId: firstActivity.conversationId, id: "loadallactivity", dateUtc: firstActivity.dateUtc };
    const separatorActivity = { from: { role:"bot_history"}, channelData: { conversationHistory:  { separator: true}}, type: "message", "originator": 1, conversationId: firstActivity.conversationId, id: "separatoractivity", dateUtc: lastActivity.dateUtc  };
    return [ loadAllMessagesActivity ,...activities, separatorActivity ];
    
}

export const hooks = {};

const onWindowFocusHandler = () => DruidWebchat_v2.isWindowFocused = true;// druidWebChatInstance?.druidWebChatDispatch && druidWebChatInstance?.druidWebChatDispatch({type: 'SET_IS_WINDOW_FOCUSED', payload: true });
const onWindowBlurHandler = () => DruidWebchat_v2.isWindowFocused = false; //druidWebChatInstance?.druidWebChatDispatch && druidWebChatInstance?.druidWebChatDispatch({type: 'SET_IS_WINDOW_FOCUSED', payload: false });

const addWindowListeners = () => {
    DruidWebchat_v2.isWindowFocused = true;
    // if(process.env.NODE_ENV !== 'development'){
    window.removeEventListener('focus', onWindowFocusHandler);
    window.removeEventListener('blur', onWindowBlurHandler);
    window.addEventListener('focus', onWindowFocusHandler);
    window.addEventListener('blur', onWindowBlurHandler);
    // }
    window.removeEventListener('resize', onResizeHandler);
    window.addEventListener('resize', onResizeHandler);
}

const initNotifications = () => {
    document.getElementById("druid-new-message-notification")?.remove();
    var audio = new Audio(druidCreateBlob(newMessageNotificationSound, 'audio/mpeg'));
    audio.controls = true;
    audio.style.display = "none";
    audio.id = "druid-new-message-notification";
    document.body.appendChild(audio);
    notificationsSounds.druidNewMessageNotification = audio;
}

const onResizeHandler = () => {
    let vh = window.innerHeight * 0.01;
    if(document && document.documentElement) {
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    const isMobile = mobileAndTabletcheck();
    const currentState = druidWebChatInstance?.getState();
    if(currentState?.isMobile !== isMobile) {
        druidWebChatInstance?.druidWebChatDispatch({type: 'SET_IS_MOBILE', payload: isMobile })
    }
}

