import { useContext, useEffect } from "react";
import { getCookie } from "../utils/cookiesUtils";
import DruidWebchatContext, { DruidWebchatContextType } from "../WebChatContext";

export const useAutoExpand = () => {
    const [druidWebChatState, druidWebChatDispatch] = useContext<DruidWebchatContextType>(DruidWebchatContext);
    
    useEffect(() => {
        if((druidWebChatState.mergedChatOptions.UI?.autoExpandEnabled || (druidWebChatState.mergedChatOptions.druidWebChatIsOpenedEnabled && getCookie("druidWebChatIsOpened") === 'true')) && !druidWebChatState.initialized && druidWebChatState.loaded) {
            druidWebChatDispatch({type:"OPEN_WEBCHAT"});
            // (druidWebChatState.mergedChatOptions.containerElement && druidWebChatState.mergedChatOptions.containerElement.click()) ||  toggleWebchatButtonRef.current?.click();
        }
      }, [druidWebChatState.mergedChatOptions.UI?.autoExpandEnabled, druidWebChatState.mergedChatOptions.druidWebChatIsOpenedEnabled , druidWebChatDispatch, druidWebChatState.initialized, druidWebChatState.loaded]);
}