import downscaleImageToDataURL from "botframework-webchat-component/lib/Utils/downscaleImageToDataURL";

export async function makeThumbnail(file, width, height, contentType, quality) {
    if (/\.(gif|jpe?g|png)$/iu.test(file.name)) {
      try {
        return await downscaleImageToDataURL(file, width, height, contentType, quality);
      } catch (error) {
        console.warn(`Web Chat: Failed to downscale image due to ${error}.`);
      }
    }
  }