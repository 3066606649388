import { DruidAction } from "../../hooks/useWebChatReducer";

const setDisabled = (isDisabled: boolean): DruidAction => {
    return {
        type: 'SET_MERGEDCHATOPTIONS',
        payload: {
          disabled: isDisabled
        }
      };
}

export default setDisabled;