import { useContext, useMemo, useState } from "react";
import { DruidWebchatRefsContextType } from "../contexts/WebChatRefsContext";
import DruidWebchatRefsContext from "../contexts/WebChatRefsContext";

export const useWebChatRefsContext = (): DruidWebchatRefsContextType => {
    return useContext<DruidWebchatRefsContextType>(DruidWebchatRefsContext);
}
export const useInitWebChatRefsContext = (): DruidWebchatRefsContextType => {
    const [wcRootRef, setWcRootRef] = useState<HTMLDivElement>();
    const [headerRef, setHeaderRef] = useState<HTMLDivElement>();
    const [headerWasDragged, setHeaderWasDragged] = useState<boolean>();


    const refsContextValue = useMemo(
        () => ({ wcRootRef, setWcRootRef, headerRef, setHeaderRef, headerWasDragged, setHeaderWasDragged }), 
        [wcRootRef, setWcRootRef, headerRef, setHeaderRef, headerWasDragged, setHeaderWasDragged]
    ); 

    return refsContextValue;
}