
import getRTLList from 'botframework-webchat-api/lib/localization/getRTLList';


export function getDruidRTLList() {
  const completeRTLList = getRTLList();
  completeRTLList.push(...["ar"]);
  return completeRTLList;
}
export function determineDirection(dir, language): 'auto' | 'ltr' | 'rtl' {
    if (dir !== 'auto' && dir) {
      return dir;
    } else if (getDruidRTLList().includes(language)) {
      return 'rtl';
    }
  
    return 'ltr';
}

export function getSideRTLAware(dir, side) {
  if(dir === 'rtl') {
    return side === 'left' ? 'right' : 'left';
  } else {
    return side;
  }
}