import { useContext, useMemo } from "react";
import DruidWebchatContext, { DruidWebchatContextType } from '../WebChatContext';
import { determineDirection } from "../utils/localeUtils";

export function useDeterminedDirection() {

    const [druidWebChatState,] = useContext<DruidWebchatContextType>(DruidWebchatContext);

    const locale = druidWebChatState.locale;
    const propsDir = druidWebChatState.mergedChatOptions.dir;
    const determinedDir = useMemo(() => determineDirection(propsDir, locale), [propsDir, locale]);

    return determinedDir;
}