import React, { createContext } from "react";

type SetAction = React.Dispatch<React.SetStateAction<HTMLDivElement | undefined>>;
type SetActionBoolean = React.Dispatch<React.SetStateAction<boolean | undefined>>;

export type DruidWebchatRefsContextType = { wcRootRef: HTMLDivElement | undefined, setWcRootRef: SetAction, headerRef: HTMLDivElement | undefined, setHeaderRef: SetAction, headerWasDragged: boolean | undefined, setHeaderWasDragged: SetActionBoolean };

const DruidWebchatRefsContext = createContext<DruidWebchatRefsContextType>(null!);
DruidWebchatRefsContext.displayName = "DruidWebchatRefsContext";


export default DruidWebchatRefsContext;