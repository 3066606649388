import { useContext, useMemo } from "react";
import { mergeChatOptions, visitMergedChatOptions } from "../utils/mergeChatOptions";
import DruidWebchatContext, { DruidWebchatContextType } from "../WebChatContext";
import { druidOverrides_adaptiveCardHostConfig, WebChatOptions } from "../WebChatOptions";
import createDefaultAdaptiveCardHostConfig from 'botframework-webchat/lib/adaptiveCards/Styles/adaptiveCardHostConfig';
import * as merge from 'deepmerge';
import { isPlainObject } from 'is-plain-object';


export function useMergedOptions(): WebChatOptions {
    const [druidWebChatState,] = useContext<DruidWebchatContextType>(DruidWebchatContext);
    return useMemo(() => {
        const computedMergedChatOptions = mergeChatOptions({UI: {}, styleOptions: {}}, druidWebChatState.mergedChatOptions);
        // computedMergedChatOptions.styleOptions = {...computedMergedChatOptions.styleOptions};
        // return druidWebChatState.mergedChatOptions;
        return visitMergedChatOptions(computedMergedChatOptions);
    }, [druidWebChatState.mergedChatOptions]);
}

export function useDruidAdaptiveCardsHostConfig(): ReturnType<typeof createDefaultAdaptiveCardHostConfig> {
    const [druidWebChatState,] = useContext<DruidWebchatContextType>(DruidWebchatContext);
    const { styleOptions, adaptiveCardsHostConfig } = druidWebChatState.mergedChatOptions;
    return useMemo(()  => {
        const ret = merge.all<any>([ createDefaultAdaptiveCardHostConfig(styleOptions!), druidOverrides_adaptiveCardHostConfig, adaptiveCardsHostConfig], { isMergeableObject: isPlainObject });
        return ret;
    }, [styleOptions, adaptiveCardsHostConfig,]);
}