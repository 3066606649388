import React, { useCallback, useEffect, useState } from "react";

export const useDraggableEffect = (draggableEnabled, ref: React.RefObject<HTMLElement>, withElementId?: string): any[]  => {
    const [wasDragged, setWasDragged] = useState(false);
    useEffect(() => {
        const onDragOverHandler = (event) => {
          event.preventDefault();
        };
        const onDropHandler = (event) => {
          const elementId = event.dataTransfer?.getData("text");
          if (elementId && elementId === (withElementId || ref.current?.id)) {
            const element = document.getElementById(elementId);
            if (element) {
              element.style.top = `${Math.max(0, event.y - 15)}px`;
              element.style.left = `${Math.max(0, event.x - 15)}px`;
            }
          }
    
          event.preventDefault();
        }
        if(draggableEnabled) {
            document.body.addEventListener("drop", onDropHandler);
            document.body.addEventListener("dragover", onDragOverHandler);
        }
        
        return () => {
          document.body.removeEventListener('dragover', onDragOverHandler);
          document.body.removeEventListener('drop', onDropHandler);
          setWasDragged(false);
        }
      }, [draggableEnabled, ref.current, setWasDragged, withElementId]);

    const onDragStarted = useCallback((event: any) => {
        
        if(event.dataTransfer && event.target){
          event.dataTransfer.effectAllowed = "all"
          event.dataTransfer.dropEffect = "move";
          event.dataTransfer.clearData();
          event.dataTransfer.setData("text/plain", withElementId || (event.target as any).id);
          setWasDragged(true);
        }
    
        return event;
      }, [setWasDragged, withElementId]);

    return [ onDragStarted, wasDragged ];
}