import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import MinimizableWebChat from './Webchat/MinimizableWebChat';
// import WebPageBackground from './WebPage.jpg';
import './App.css';
import { WebChatOptions, webChatDefaultOptions as defaultOptions } from './Webchat/WebChatOptions';
import { useWebChatReducer } from './Webchat/hooks/useWebChatReducer';
import DruidWebchatContext from './Webchat/WebChatContext';
import DruidWebchatRefsContext from './Webchat/contexts/WebChatRefsContext';
import { useInitWebChatRefsContext } from './Webchat/hooks/useWebChatRefsContext';
import { useLoadConfiguration } from './Webchat/hooks/useLoadConfiguration';
import classNames from 'classnames';
import loadable from '@loadable/component';
import * as merge from 'deepmerge';
import { isPlainObject } from 'is-plain-object';
import { determineDirection } from "./Webchat/utils/localeUtils";

export const LoadableWebchat = loadable(() => {
  return import(
    /* webpackChunkName: "modules" */
    './Webchat/WebChat');
});



const App = forwardRef((initialOptions: WebChatOptions, ref) => {
  const contextValue = useWebChatReducer(initialOptions);
  

  const refsContextValue = useInitWebChatRefsContext();

  const wcRootRef = useRef<HTMLDivElement>(null);

  const [druidWebChatState, druidWebChatDispatch, getState, druidStyleToEmotionObject] = contextValue;

  const { styleOptions: { primaryFont } = {},
    UI: { lineHeight, rootFontSize, transcriptFontSize } = {},
    dir: propsDir,
    customDruidStyleSet: { webchatRoot: webchatRootStyleSet = {}} = {}
  } = druidWebChatState.mergedChatOptions;

  const { locale } = druidWebChatState;

  useImperativeHandle(ref, () => ({
    druidWebChatDispatch,
    // bfDispatch: bfStore.dispatch
    bfDispatch: druidWebChatState.store?.dispatch,
    getState: getState
  }), [druidWebChatDispatch, druidWebChatState.store?.dispatch, getState]); //bfStore.dispatch, 

  useEffect(() => {
    refsContextValue.setWcRootRef(wcRootRef.current || undefined);
  }, [refsContextValue]);

  const determinedDir = useMemo(() => determineDirection(propsDir, locale), [propsDir, locale]);
  // const determinedDir = useDeterminedDirection(contextValue);
  // moved to app.tsx
  useLoadConfiguration({ contextValue, initialOptions });


  const ROOT_STYLE = merge.all([
  {
    '&.druid__web-chat__root-container': {
        fontFamily: primaryFont,
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        lineHeight: lineHeight,
        fontSize: rootFontSize,
        '& .druid__web-chat__chat-container': {
          overflow: 'hidden',
          flexGrow: 1,
          fontSize: transcriptFontSize,
          height: 'auto' // override of bfv4 height: 100%. this is flex, use flexGrow: 1 above
        }
      }
  }, webchatRootStyleSet], { isMergeableObject: isPlainObject });

  const rootClassName = druidStyleToEmotionObject(ROOT_STYLE) + '';


  return (
   
      <DruidWebchatContext.Provider value={contextValue}>
        <DruidWebchatRefsContext.Provider value={refsContextValue}>
          <div ref={wcRootRef} className={classNames("druid__web-chat__root-container", determinedDir === 'ltr' ? 'druid__web-chat__root-direction_ltr' : 'druid__web-chat__root-direction_rtl', rootClassName + '')} >
            {
                initialOptions.isWidget && !initialOptions.UI?.parentElement ?
                  <MinimizableWebChat ref={ref} initialOptions={initialOptions} /> // seems like passing the ref is no longer needed. see (note 1) comment below
                  :
                  (druidWebChatState.loaded === true && <LoadableWebchat //loaded used for reset to take effect. druidWebchatState.store is null after reset. feel free to remove condition + remove reload button on isWidget == false
                    // ref={ref} // (note 1): should we forward the initial ref ? there is a problem -- druidWebchatInstance becomes true/false/null because of shouldRenderWebchat imperativeHandler from WebChat.tsx. if ref is needed, add here another useRef and forward it
                    className="druid__web-chat__chat-container"
                    initialOptions={initialOptions}
                  />)

            }
          </div>
        </DruidWebchatRefsContext.Provider>
      </DruidWebchatContext.Provider>

    // <DruidWebchatContext.Provider value={[druidWebChatState, druidWebChatDispatch, getState, druidStyleToEmotionObject]}>
    //   {/* NEED TO FIND A WAY TO dynamic import WebChat component. if loaded here, dynamic import does not work */ }
    // </DruidWebchatContext.Provider>
  );
});
// <div className="App">
// {/* <img alt="product background" src={WebPageBackground} /> */}

// </div>
// );

export default App;
