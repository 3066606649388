export function randomHash(length) {
    length = length || 6;
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result.toLowerCase();
}
export function expandStringToLength(source, length) {
    if(!source) {
        return '';
    }
    var ret = source;
    while(ret.length < length) {
        ret += ret;
    }

    return ret.substring(0, length);
}