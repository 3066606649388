import * as merge from 'deepmerge';
import { isPlainObject } from 'is-plain-object';
import { WebChatOptions } from '../WebChatOptions';

export function mergeChatOptions(...args: Partial<WebChatOptions>[]) {
    return merge.all<WebChatOptions>([...args], { isMergeableObject: isPlainObject })
}

export function visitMergedChatOptions(mergedChatOptions: WebChatOptions) {
    Object.keys(mergedChatOptions.UI!).forEach(key => {
      const val = mergedChatOptions.UI![key];
      const typeof_val = typeof val;
      if(typeof_val === 'string') {
        // mergedChatOptions.UI![key] = val.startsWith("$") ? ((mergedChatOptions.styleOptions![val.replace('$','')]) || (mergedChatOptions.UI![val.replace('$','')]))  : val;
        mergedChatOptions.UI![key] = val.startsWith("$") ? tryGetValue(mergedChatOptions, val)  : val;

      }
    });

    Object.keys(mergedChatOptions.styleOptions!).forEach(key => {
      const val = mergedChatOptions.styleOptions![key];
      const typeof_val = typeof val;
      if(typeof_val === 'string') {
        // mergedChatOptions.styleOptions![key] = val.startsWith("$") ? ((mergedChatOptions.styleOptions![val.replace('$','')]) || (mergedChatOptions.UI![val.replace('$','')]))  : val;
        mergedChatOptions.styleOptions![key] = val.startsWith("$") ? tryGetValue(mergedChatOptions, val)  : val;

        // mergedChatOptions.styleOptions![key] = val.startsWith("$") ? tryGetValue(mergedChatOptions, val)  : val;
      }
    });


    return mergedChatOptions;
}

function tryGetValue(rootObject: any, propName: any) {
  
  let nextVal = rootObject.styleOptions[propName.replace('$','')] || rootObject.UI[propName.replace('$','')];

  if(nextVal && nextVal.startsWith("$")) {
    nextVal = tryGetValue(rootObject, nextVal);
  }

  return nextVal;

}

function propertiesToArray(obj) {
    const isObject = val =>
      val && typeof val === 'object' && !Array.isArray(val);
  
    const addDelimiter = (a, b) =>
      a ? `${a}.${b}` : b;
  
    const paths = (obj = {}, head = '') => {
      return Object.entries(obj)
        .reduce((product, [key, value]) => 
          {
            let fullPath = addDelimiter(head, key)
            return isObject(value) ?
              product.concat(paths(value as any, fullPath))
            : product.concat(fullPath)
          }, []);
    }
  
    return paths(obj);
  }